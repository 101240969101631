<template>
  <div>
    <VCreditCard :trans="translations" @change="creditInfoChanged" />

    <b-button :disabled="carregando" block variant="success" @click="adicionar">
      <b-spinner v-if="carregando"></b-spinner>
      <span v-else>Adicionar Cartão</span>
    </b-button>
  </div>
</template>
<!-- eslint-disable -->
<script>
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";
import service from "../services";
const translations = {
  name: {
    label: "Nome",
    placeholder: "Nome completo",
  },
  card: {
    label: "Número do Cartão",
    placeholder: "Número do Cartão",
  },
  expiration: {
    label: "Expiração",
  },
  security: {
    label: "Código de segurança",
    placeholder: "Código",
  },
};

export default {
  data() {
    return {
      carregando: false,
      translations,
      name: "",
      cardNumber: "",
      expiration: "",
      security: "",
    };
  },
  props: ["idProspect", "typeCard"],
  methods: {
    creditInfoChanged(values) {
      for (const key in values) {
        this[key] = values[key];
      }
    },
    adicionarCartao(obj) {
      this.carregando = true;
      service
        .post("Associado", "AdicionarCartao", {
          ...obj,
          idProspect: this.idProspect,
        })
        .then((res) => {
          console.log(res);
          this.carregando = false;
          this.$emit("adicionar", {
            name: this.name,
            cardNumber: this.cardNumber,
            expiration: this.expiration,
            security: this.security,
            selecionado: true,
          });
        })
        .catch((e) => {
          this.$bvToast.toast("Falha ao adicionar o Cartão", {
            title: "Atenção!",
            solid: true,
            variant: "danger",
            toaster: "b-toaster-top-full",
            appendToast: false,
            autoHideDelay: 2500,
          });
          this.carregando = false;
        });
    },
    adicionar() {
      var msg = [];
      if (this.name == "") {
        msg.push("Preencha o Nome Completo");
      }

      if (this.cardNumber == "") {
        msg.push("Preencha o número do Cartão");
      } else {
        if (this.cardNumber.length != 19) {
          msg.push("Preencha o número do Cartão Completo");
        }
      }

      if (this.expiration == "") {
        msg.push("Preencha o vencimento do Cartão");
      } else {
        if (this.expiration.length != 5) {
          msg.push("Preencha o vencimento do Cartão MM/YY");
        }
      }

      if (this.security == "") {
        msg.push("Preencha o código de segurança do Cartão");
      } else {
        if (this.security < 3) {
          msg.push("Preencha o código de segurança do Cartão corretamente");
        }
      }

      if (msg.length > 0) {
        const h = this.$createElement;
        var arrAux = [];
        msg.forEach((element) => {
          arrAux.push(h("p", {}, element));
        });
        // Create the message
        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, arrAux);

        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: "Atenção!",
          solid: true,
          variant: "danger",
          toaster: "b-toaster-top-full",
          appendToast: false,
          autoHideDelay: 3500,
        });
        return;
      } else {
        this.adicionarCartao({
          name: this.name,
          cardNumber: this.cardNumber,
          expiration: this.expiration,
          security: this.security,
          selecionado: true,
        });
        /*this.$emit("adicionar", {
          name: this.name,
          cardNumber: this.cardNumber,
          expiration: this.expiration,
          security: this.security,
          selecionado: true,
        });*/
        console.log({
          name: this.name,
          cardNumber: this.cardNumber,
          expiration: this.expiration,
          security: this.security,
        });
      }
    },
  },
  components: {
    VCreditCard,
  },
};
</script> 