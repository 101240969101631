export default {
    dataURItoBlob(dataURI) {
        const bytes =
            dataURI.split(",")[0].indexOf("base64") >= 0
                ? atob(dataURI.split(",")[1])
                : unescape(dataURI.split(",")[1]);
        const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    },
    resizeVideo({ file, maxSize }) {
        const reader = new FileReader();
        const canvas = document.createElement("canvas");

        const resize = () => {
            let { width, height } = file;

            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;
            // canvas.getContext("2d").drawImage(file, 0, 0, width, height);

            const dataUrl = canvas.toDataURL("video/mp4", 0.5);
            return dataUrl;
            //return dataURItoBlob(dataUrl);
        };

        return new Promise((ok, no) => {
            if (!file.type.match(/video.*/)) {
                no(new Error("Not an video"));
                return;
            }

            reader.onload = (readerEvent) => {
                file.onload = () => ok(resize());
                file.src = readerEvent.target.result;
            };

            reader.readAsDataURL(file);
        });
    },
};
