<template>
    <span>
        <input id="newFileCamera" refs="newFileCamera" type="file" accept="video/mp4, video/x-m4v, video/*"
            :capture="capture ? capture : 'environment'" hidden @change="readURL" />
        <div class="containericone" @click="chooseFiles">
            <font-awesome-icon class="icone" :icon="['fa', 'video']" />
        </div>
    </span>
</template>
<!-- eslint-disable -->
<script>
import Camera from "easy-js-camera";
import videoFunctions from "./../services/videoFunctions";
Camera.isCameraSupported();
export default {
    name: "DashBoard",
    props: { capture: String },
    data() {
        return {
            decoded: {},
            menuOpen: false,
            resizedImg: null,
            listaVideo: [],
            form: {
                video: [],
            },
            video: null,
        };
    },
    components: {},
    methods: {
        chooseFiles() {
            document.getElementById("newFileCamera").click();
        },
        readURL(event) {
            var files = event.target.files[0];

            const readSuccess = (evt) =>{
                        var obj = {
                            name: files.name,
                            base: evt.target.result,
                            blob: videoFunctions.dataURItoBlob(evt.target.result),
                        };
                        this.$emit("FotoTirada", obj);
            
            }

            var reader = new FileReader();
    reader.onload = readSuccess;                                            
    reader.readAsDataURL(files);    
            
        },
        setvideo(event) {
            // console.log(event)
        }
    },
    mounted() { },
};
</script>
<style lang="scss" scoped>
.containericone {
    text-align: center;
    border-radius: 10px;
    padding: 10px 20px;
    -webkit-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
    width: 100px;
    border: 1px solid #e7e7e7;

    .icone {
        font-size: 50px;
        color: #333;
    }
}
</style>
