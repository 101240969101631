import { render, staticRenderFns } from "./AceiteCotacao_novo.vue?vue&type=template&id=2cf44ccb&scoped=true&"
import script from "./AceiteCotacao_novo.vue?vue&type=script&lang=js&"
export * from "./AceiteCotacao_novo.vue?vue&type=script&lang=js&"
import style0 from "./AceiteCotacao_novo.vue?vue&type=style&index=0&id=2cf44ccb&lang=scss&scoped=true&"
import style1 from "./AceiteCotacao_novo.vue?vue&type=style&index=1&lang=css&"
import style2 from "./AceiteCotacao_novo.vue?vue&type=style&index=2&id=2cf44ccb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf44ccb",
  null
  
)

export default component.exports